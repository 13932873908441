import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./AppContext";
import { Navigation } from "./navigation";

const TermsAndConditions = () => {

  return (
    <>    
      <div className="terms-container">
        
        <h1 style={{ fontSize: "50px", fontWeight: "bold" }}>
          Terms and Conditions
        </h1>
        <p style={{ fontWeight: "650", color: "black", fontSize: "19.5px" }}>
          Welcome to DREMS! These Terms and Conditions govern your use of the
          DREMS application (the "App") operated by Zarif Quest International
          Pvt. Ltd. ("we", "our", or "us") in India. By downloading, accessing,
          or using the App, you agree to comply with and be bound by these
          terms. If you do not agree with any part of these terms, please do not
          use the App.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By using the App, you acknowledge that you have read, understood, and
          agree to these Terms and Conditions and our Privacy Policy.
        </p>

        <h2>2. User Eligibility</h2>
        <p>
          The App is intended for use by students, parents, guardians, teachers,
          and authorized school personnel in India. Users must be over 18 years
          old or have parental/guardian consent to use the App.
        </p>

        <h2>3. Account Registration and Security</h2>
        <ul>
          <li>
            Users may need to create an account to access certain features.
          </li>
          <li>
            You are responsible for maintaining the confidentiality of your
            account credentials and for any activity that occurs under your
            account.
          </li>
          <li>
            Notify us immediately if you believe your account has been
            compromised.
          </li>
        </ul>

        <h2>4. Permitted Use and Restrictions</h2>
        <p style={{ fontWeight: "bold" }}>
          DREMS is provided solely for educational purposes. You agree to:
        </p>
        <ul>
          <li>
            Use the App in accordance with these Terms and all applicable laws
            in India.
          </li>
          <li>Not use the App for any commercial or unauthorized purposes.</li>
          <li>
            Not disrupt the functionality of the App or interfere with other
            users' access.
          </li>
        </ul>

        <h2>5. Privacy and Data Protection</h2>
        <p>
          Your privacy is important to us. Our Privacy Policy explains how we
          collect, use, and protect your personal information. By using the App,
          you agree that we can collect and use your data in accordance with
          Indian privacy laws and regulations.
        </p>

        <h2>6. Content Ownership and Intellectual Property</h2>
        <ul>
          <li>
            All content, including text, graphics, logos, images, and software
            within the App, is the property of Zarif Quest International Pvt.
            Ltd. or licensed to us.
          </li>
          <li>
            You may not copy, modify, distribute, sell, or lease any part of the
            App or its content without our written permission.
          </li>
        </ul>

        <h2>7. User-Generated Content</h2>
        <ul>
          <li>
            Users may submit assignments, messages, and other content via the
            App.
          </li>
          <li>
            You retain ownership of your content but grant us a non-exclusive,
            royalty-free license to use, distribute, and display it within the
            App.
          </li>
          <li>
            We reserve the right to remove or modify any user-generated content
            that violates these Terms or is inappropriate.
          </li>
        </ul>

        <h2>8. Third-Party Services</h2>
        <p>
          The App may link to or integrate with third-party services. We are not
          responsible for the content, policies, or practices of any third-party
          service.
        </p>

        <h2>9. Modification of Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting in the App, and continued
          use of the App will signify acceptance of the modified Terms.
        </p>

        <h2>10. Limitation of Liability</h2>
        <ul>
          <li>
            To the fullest extent permitted by Indian law, Zarif Quest
            International Pvt. Ltd. is not liable for any indirect, incidental,
            or consequential damages arising from the use of the App.
          </li>
          <li>
            We do not guarantee that the App’s functionality will be error-free,
            uninterrupted, or that defects will be corrected promptly.
          </li>
        </ul>

        <h2>11. Indemnity</h2>
        <p>
          You agree to indemnify and hold harmless Zarif Quest International
          Pvt. Ltd. and its employees, agents, and affiliates from any claims,
          damages, or costs arising from your use of the App or violation of
          these Terms.
        </p>

        <h2>12. Termination</h2>
        <p>
          We reserve the right to terminate or suspend your access to the App at
          any time, without notice, for conduct that we believe violates these
          Terms or is harmful to other users.
        </p>

        <h2>13. Governing Law and Dispute Resolution</h2>
        <p>
          These Terms and Conditions are governed by the laws of India. Any
          disputes arising under these Terms shall be resolved in accordance
          with Indian law and in the courts of [City, State, e.g., Bengaluru,
          Karnataka].
        </p>

        <h2>14. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at{" "}
          <a href="mailto:support@dariff.com"> support@dariff.com</a>
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
