import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [privacyBtn, setPrivacyBtn] = useState(false);
  const [isLoading, setDataLoader] = useState(true);

  return (
    <AppContext.Provider value={{ privacyBtn, setPrivacyBtn, isLoading, setDataLoader }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
