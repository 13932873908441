
// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const appConfig = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '',
    theme: 'light',
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    firebase: {
      apiKey: "AIzaSyDDMAuJN8Kuve8w5uoxcUs4wlOF4-MI8xY", // these commented one is from abuzarlab
      authDomain: "da-riff.firebaseapp.com",
      projectId: "da-riff",
      storageBucket: "da-riff.appspot.com",
      messagingSenderId: "288471376843",
      appId: "1:288471376843:web:5f2b03aa4b1b65ce81cc4e",
      measurementId: "G-6NRGGKRR0F",
      // apiKey: "AIzaSyBWHRPGeP4Vt_261NWjw1oBQTf-OKW4PcQ",
      // authDomain: "zarifquest-81ab7.firebaseapp.com",
      // projectId: "zarifquest-81ab7",
      // storageBucket: "zarifquest-81ab7.firebasestorage.app",
      // messagingSenderId: "352800336414",
      // appId: "1:352800336414:web:7a1ad9fdfba5744c309c0f",
      // measurementId: "G-8RW5CX3M1V"
    },
  };


// Initialize Firebase
const app = firebase.initializeApp(appConfig.firebase);
// const analytics = getAnalytics(app);