import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import Review from './Review';


class ChatBotComponent extends Component {
    render() {
      return (
        <ChatBot headerTitle='How may i help you'
          steps={[
            {
              id: '1',
              message: 'How may i help you?',
              trigger: 'services-fields',
              user: true
            },
            {
              id: 'services-fields',
              user: true,
              options: [
                { value: 'mobileapp', label: 'Mobile App Development', trigger: 'mobileapp' },
                { value: 'webapp', label: 'Web App Development', trigger: 'webapp' },
                { value: 'otherquery', label: 'Other Query', trigger: 'otherquery' },
              ],
              
            },
            {
              id: 'mobileapp',
              message: 'Thanks! Please call us on +91-8073410803/+91-8553312681 or email on support@dariff.com',
              end: true,
            },
            {
              id: 'webapp',
              message: 'Thanks! Please call us on +91-8073410803/+91-8553312681 or email on support@dariff.com',
              end: true,
            },
            {
              id: 'otherquery',
              message: 'Thanks! Please call us on +91-8073410803/+91-8553312681 or email on support@dariff.com',
              end: true,
            },
            {
              id: 'name',
              user: true,
              trigger: '3',
            },
            {
              id: '3',
              message: 'Hi {previousValue}! What is your gender?',
              trigger: 'gender',
            },
            {
              id: 'gender',
              options: [
                { value: 'male', label: 'Male', trigger: '5' },
                { value: 'female', label: 'Female', trigger: '5' },
              ],
            },
            {
              id: '5',
              message: 'How old are you?',
              trigger: 'age',
            },
            {
              id: 'age',
              user: true,
              trigger: '7',
              validator: (value) => {
                if (isNaN(value)) {
                  return 'value must be a number';
                } else if (value < 0) {
                  return 'value must be positive';
                } else if (value > 120) {
                  return `${value}? Come on!`;
                }
  
                return true;
              },
            },
            {
              id: '7',
              message: 'Great! Check out your summary',
              trigger: 'review',
            },
            {
              id: 'review',
              component: <Review />,
              asMessage: true,
              trigger: 'update',
            },
            {
              id: 'update',
              message: 'Would you like to update some field?',
              trigger: 'update-question',
            },
            {
              id: 'update-question',
              options: [
                { value: 'yes', label: 'Yes', trigger: 'update-yes' },
                { value: 'no', label: 'No', trigger: 'end-message' },
              ],
            },
            {
              id: 'update-yes',
              message: 'What field would you like to update?',
              trigger: 'update-fields',
            },
            {
              id: 'update-fields',
              options: [
                { value: 'name', label: 'Name', trigger: 'update-name' },
                { value: 'gender', label: 'Gender', trigger: 'update-gender' },
                { value: 'age', label: 'Age', trigger: 'update-age' },
              ],
            },
            {
              id: 'update-name',
              update: 'name',
              trigger: '7',
            },
            {
              id: 'update-gender',
              update: 'gender',
              trigger: '7',
            },
            {
              id: 'update-age',
              update: 'age',
              trigger: '7',
            },
            {
              id: 'end-message',
              message: 'Thanks! Your data was submitted successfully!',
              end: true,
            },
          ]}
        />
      );
    }
  }
  
  export default ChatBotComponent;